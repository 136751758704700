export const NullFormat = { id: 0 };

export const Formats = {
  Face2Face: 1 << 0,
  Trigon: 1 << 1,
  Tetragon: 1 << 2,
  Pentagon: 1 << 3,
  Decagon: 1 << 4,
  Polygon: 1 << 5,
  Unlimited: 1 << 6,
};

const staticUnlimitedOne = {
  1: 10.00,
  2: 6.67,
  3: 3.33,
  4: 2.50,
  5: 2.14,
  6: 1.79,
  7: 1.43,
  8: 1.07,
  9: 0.71,
  10: 0.36,
};

const formatDefault = (format) => {
  const { winnerDescription, additionalDesc, winnerCount } = format;

  return {
    winnerDescription,
    additionalDesc,
    winnerCount,
  };
};

const formatSmall = (maxPlayer, lineLen) => {
  const onePer = lineLen / 100;
  const firstPlace = Number((maxPlayer / onePer).toFixed(1));
  const secondPlace = Number(((lineLen - maxPlayer) / onePer).toFixed(1));

  return {
    winnerDescription: 'Two winners',
    additionalDesc: `
      First place - ${firstPlace}%;
      Second place - ${secondPlace}%;
    `,
    winnerCount: 2,
  };
};

const formatPentagon = (lineLen) => {
  const desc = {
    6: [83.34, 16.66],
    7: [71.43, 28.57],
    8: [62.5, 37.5],
    9: [55.56, 33.33, 11.11],
  };
  const cur = desc[lineLen] || [];
  const isThree = Boolean(cur[2]);

  return {
    winnerDescription: `${isThree ? 'Three' : 'Two'} winners`,
    additionalDesc: `
      First place - ${cur[0]}%;
      Second place - ${cur[1]}%;
      ${isThree ? `Third place - ${cur[2]}%;` : ''}
    `,
    winnerCount: cur.length,
  };
};

const formatDecagon = (lineLen) => {
  const desc = {
    11: [54.54, 36.36, 9.1],
    12: [50, 33.34, 16.66],
    13: [50, 33.34, 16.66],
    14: [42.86, 28.57, 21.43, 7.14],
    15: [40, 26.67, 20, 13.33],
    16: [37.5, 25, 18.75, 12.5, 6.25],
    17: [41.18, 23.53, 17.65, 11.76, 5.88],
    18: [44.44, 22.22, 16.67, 11.11, 5.56],
    19: [47.37, 21.05, 15.79, 10.53, 5.26],
  };
  const cur = desc[lineLen] || [];
  const isFourth = Boolean(cur[3]);
  const isFifth = Boolean(cur[4]);
  const title = isFifth ? 'Five' : isFourth ? 'Four' : 'Three';

  return {
    winnerDescription: `${title} winners`,
    additionalDesc: `
      First place - ${cur[0]}%;
      Second place - ${cur[1]}%;
      Third place - ${cur[2]}%;
      ${isFourth ? `Fourth place - ${cur[3]}%;` : ''}
      ${isFifth ? `Fifth place - ${cur[4]}%;` : ''}
    `,
    winnerCount: cur.length,
  };
};

const formatUnlimited = (lineLen) => {
  if (lineLen < 100) {
    if (lineLen <= 5) {
      return formatMetaById(Formats.Face2Face);
    }

    if (lineLen > 5 && lineLen < 10) {
      return formatPentagon(lineLen);
    }

    if (lineLen >= 10 && lineLen < 20) {
      return lineLen === 10 ? formatMetaById(Formats.Decagon) : formatDecagon(lineLen);
    }

    if (lineLen >= 20) {
      return formatMetaById(Formats.Polygon);
    }
  }

  const N = Math.round(lineLen * 0.1);
  const arP = (1 + N) / 2 * N;
  const result = [];

  for (let i = 1; i <= N; i++) {
    const per = 70 / arP * (N + 1 - i);
    const finalPer = Number((staticUnlimitedOne[i] ? staticUnlimitedOne[i] + per : per).toFixed(2));
    result.push(`${i} - ${finalPer}%;`);
  }

  return {
    winnerDescription,
    additionalDesc: result.join(''),
    winnerCount: result.length,
  };
};

export const calculateAdditionalPlace = (format, lineLen) => {
  const { maxPlayer, winnerCount } = format;

  if (maxPlayer === 100) {
    return formatUnlimited(lineLen);
  }

  if (maxPlayer === 50) {
    return formatDefault(format);
  }

  if (maxPlayer >= lineLen) {
    return formatDefault(format);
  }

  if (winnerCount === 1) {
    return formatSmall(maxPlayer, lineLen);
  }

  if (maxPlayer === 5) {
    return formatPentagon(lineLen);
  }

  if (winnerCount === 3) {
    return formatDecagon(lineLen);
  }

  return {};
};

export const FormatsMeta = [
  {
    id: Formats.Face2Face,
    type: 'f2f',
    multiplier: 'X2',
    maximum: 1,
    description: '2 players',
    winnerCount: 1,
    maxPlayer: 2,
    winnerDescription: 'All or nothing',
  },
  {
    id: Formats.Trigon,
    type: Formats.Trigon,
    multiplier: 'X3',
    description: '3 players',
    maximum: 2,
    winnerCount: 1,
    maxPlayer: 3,
    winnerDescription: 'All or nothing',
  },
  {
    id: Formats.Tetragon,
    type: Formats.Tetragon,
    multiplier: 'X4',
    maximum: 3,
    description: '4 players',
    winnerCount: 1,
    maxPlayer: 4,
    winnerDescription: 'All or nothing',
  },
  {
    id: Formats.Pentagon,
    type: Formats.Pentagon,
    multiplier: 'X5',
    maximum: 4,
    description: '5 players',
    winnerCount: 2,
    maxPlayer: 5,
    winnerDescription: 'All or nothing',
  },
  {
    id: Formats.Decagon,
    type: Formats.Decagon,
    multiplier: 'Decagon',
    maximum: 5,
    description: '10 players',
    winnerDescription: 'Three winners',
    winnerCount: 3,
    maxPlayer: 10,
    additionalDesc: `
      First place - 60%;
      Second place - 30%;
      Third place -  10%;
    `,
  },
  {
    id: Formats.Polygon,
    type: Formats.Polygon,
    multiplier: 'Polygon',
    maximum: 20,
    description: 'up to 50 players',
    winnerDescription: 'Five winners',
    maxPlayer: 50,
    winnerCount: 5,
    additionalDesc: `
      First place - 50%;
      Second place - 25%;
      Third place -  13%;
      Forth place - 7%;
      Fifth place - 5%;
    `,
  },
  {
    id: Formats.Unlimited,
    type: Formats.Unlimited,
    maximum: 1000,
    multiplier: 'Unlimited',
    description: 'up to 100 players',
    winnerDescription: 'Multiple winners',
    maxPlayer: 100,
    additionalDesc: `
      First place - 50%;
      Second place - 25%;
      Third place -  13%;
      Forth place - 7%;
      Fifth place - 5%;
    `,
  },
];

export function formatMetaById(id) {
  return FormatsMeta.find((f) => f.id === id) || NullFormat;
}
