import { Sports } from './sports';

export * from './sports';
export * from './football';
export * from './format';
export * from './price';
export * from './states';

export function sportFromEvent(event) {
  if (event.__typename === 'FootballEvent') {
    return Sports.Football;
  }

  return -1;
}

export const getRulesName = (ticket = {}) => {
  const haveRules = ticket.rounds && ticket.rounds.length;

  return haveRules ? 'Playoff' : 'Tour';
};
