export const Sports = {
  Football: 1 << 0,
  Basketball: 1 << 1,
  Tennis: 1 << 2,
};

export const SportsStrings = {
  Football: 'Football',
  Basketball: 'Basketball',
  Tennis: 'Tennis',
};

export const SportsMeta = [
  {
    id: Sports.Football,
    type: 'f',
    name: SportsStrings.Football,
  },
  /* {
      id: Sports.Basketball,
      type: 'b',
      name: SportsStrings.Basketball,
    },
    {
      id: Sports.Tennis,
      type: 't',
      name: SportsStrings.Tennis,
    }, */
];

export function SportMetaById(sportId) {
  return SportsMeta.find((m) => m.id === sportId);
}
