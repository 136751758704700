export const NullPrice = { id: 0 };

export const Prices = {
  One: 1 << 0,
  Two: 1 << 1,
  Five: 1 << 2,
  Ten: 1 << 3,
  Twenty: 1 << 4,
  Fifty: 1 << 5,
  OneHundred: 1 << 6,
  Unlimited: 1 << 7,
};

export const PricesMeta = [
  {
    id: Prices.One,
    type: 'one',
    name: '1',
    amount: 100,
  },
  {
    id: Prices.Two,
    type: 'two',
    name: '2',
    amount: 200,
  },
  {
    id: Prices.Five,
    type: 'five',
    name: '5',
    amount: 500,
  },
  {
    id: Prices.Ten,
    type: 'ten',
    name: '10',
    amount: 1000,
  },
  {
    id: Prices.Twenty,
    type: 'twenty',
    name: '20',
    amount: 2000,

  },
  {
    id: Prices.Fifty,
    type: 'fifty',
    name: '50',
    amount: 5000,

  },
  {
    id: Prices.OneHundred,
    type: 'onehundred',
    name: '100',
    amount: 10000,
  },
  {
    id: Prices.Unlimited,
    type: 'custom',
    name: 'Hot Caldron',
    amount: Infinity,
  },
];

export function priceMetaById(id) {
  return PricesMeta.find((p) => p.id === id) || NullPrice;
}

export function priceMetaByAmount(amount) {
  return amount > 10000 ? {
    ...priceMetaById(Prices.Unlimited),
    amount,
  } : (PricesMeta.find((p) => p.amount === amount) || NullPrice);
}

export function priceToAmount(price, userAmount) {
  switch (price) {
    case Prices.One: return 1;
    case Prices.Two: return 2;
    case Prices.Five: return 5;
    case Prices.Ten: return 10;
    case Prices.Twenty: return 20;
    case Prices.Fifty: return 50;
    case Prices.OneHundred: return 100;
    case Prices.Unlimited: return userAmount;
    default: return -1;
  }
}
