import cx from 'classnames';
import { useState } from 'react';
import Tippy from '@tippyjs/react';

import { FootballScores, FootballScoresStrings } from 'utils/game';

import Info from 'public/icons/info.svg';

import styles from './probability.module.css';

const OtherInfo = ({
  predictions, scoreGrid, elem, absolute = false, rightPosition = false,
}) => {
  const [openTippy, setOpenTippy] = useState(false);

  const missedScores = scoreGrid.reduce((acc, scores) => {
    const avalible = scores.filter((s) => predictions[s]
        && (s !== FootballScores.MaxZero && s !== FootballScores.ZeroMax && s !== FootballScores.Even));
    return acc.concat(avalible);
  }, []);

  return (
    <Tippy
      onClickOutside={() => setOpenTippy(false)}
      visible={openTippy}
      interactive
      content={(
        <div className="text-center whitespace-no-wrap">
          Any Other Scores
          <p className={cx('text-sm px-4 pt-1', styles.infoElem)}>{elem}</p>
          {missedScores.length > 0 && (
          <p className="text-sm px-4 pt-1">
            <b>Except:</b>
            {' '}
            {missedScores.map((s) => FootballScoresStrings[`${s}`]).join(', ')}
          </p>
          )}
        </div>
)}
    >
      <strong className={cx('ml-1', { [styles.isInfoAbs]: absolute, [styles.isRight]: rightPosition })}>
        <Info
          onClick={(ev) => {
            ev.stopPropagation();
            setOpenTippy(true);
          }}
          className="cursor-pointer inline-block w-6 h-6"
        />
      </strong>
    </Tippy>
  );
};

export default OtherInfo;
