import cx from 'classnames';

import styles from './loader.module.css';

const Loader = ({ className = '' }) => (
  <div className={cx(styles.loader, className)}>
    <div className={styles.loaderInd}>
      <div className={styles.dot} />
      <div className={styles.dot} />
      <div className={styles.dot} />
      <div className={styles.dot} />
      <div className={styles.dot} />
    </div>
  </div>
);

export default Loader;
