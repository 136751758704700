import { round } from 'utils/number';
import OtherInfo from 'components/bets/probability/otherInfo';

export const FootballScores = {
  OneZero: 1 << 3,
  TwoZero: 1 << 4,
  TwoOne: 1 << 5,
  ThreeZero: 1 << 6,
  ThreeOne: 1 << 7,
  MaxZero: 1 << 8,
  ZeroZero: 1 << 0,
  OneOne: 1 << 1,
  Even: 1 << 2,
  ZeroOne: 1 << 9,
  ZeroTwo: 1 << 10,
  OneTwo: 1 << 11,
  ZeroThree: 1 << 12,
  OneThree: 1 << 13,
  ZeroMax: 1 << 14,
};

export const FootballScoresStrings = {
  [`${FootballScores.OneZero}`]: '1:0',
  [`${FootballScores.TwoZero}`]: '2:0',
  [`${FootballScores.TwoOne}`]: '2:1',
  [`${FootballScores.ThreeZero}`]: '3:0',
  [`${FootballScores.ThreeOne}`]: '3:1',
  [`${FootballScores.MaxZero}`]: 'X:x',
  [`${FootballScores.ZeroOne}`]: '0:1',
  [`${FootballScores.ZeroTwo}`]: '0:2',
  [`${FootballScores.OneTwo}`]: '1:2',
  [`${FootballScores.ZeroThree}`]: '0:3',
  [`${FootballScores.OneThree}`]: '1:3',
  [`${FootballScores.ZeroMax}`]: 'x:X',
  [`${FootballScores.ZeroZero}`]: '0:0',
  [`${FootballScores.OneOne}`]: '1:1',
  [`${FootballScores.Even}`]: 'X:X',
};

export const FootballS1 = [
  [FootballScores.TwoOne, FootballScores.TwoZero, FootballScores.OneZero],
  [FootballScores.ThreeOne, FootballScores.ThreeZero],
  [FootballScores.MaxZero],
];

export const FootballS2 = [
  [FootballScores.ZeroZero],
  [FootballScores.OneOne],
  [FootballScores.Even],
];

export const FootballS3 = [
  [FootballScores.ZeroOne, FootballScores.ZeroTwo, FootballScores.OneTwo],
  [FootballScores.ZeroThree, FootballScores.OneThree],
  [FootballScores.ZeroMax],
];

export function extractScores(scores) {
  return Object.keys(FootballScores).filter((k) => (scores & FootballScores[k]) > 0);
}

export function extractScoresAsId(scores) {
  return extractScores(scores).map((k) => FootballScores[k]);
}

export function scoresToString(scores) {
  return extractScores(scores).map((k) => FootballScoresStrings[`${FootballScores[k]}`]).join(' or ');
}

export function extractedToString(extracted) {
  return extracted.map((k) => FootballScoresStrings[k]);
}

export function probToCoef(prob) {
  return round(100 / prob, 2);
}

export function getProbability(scores, probability) {
  if (!scores || !probability) {
    return '-';
  }
  const extracted = extractScoresAsId(scores);
  const probs = extracted.map((score) => probability[score]);
  const result = probs.reduce((acc, n) => acc + n, 0);

  return probToCoef(result);
}

export const getScoreSum = (scoreGrid, predictions) => {
  const result = scoreGrid.map((sl) => sl.map((s) => predictions[s])
    .reduce((acc, b) => acc + b, 0)).reduce((acc, b) => acc + b, 0);

  return probToCoef(result);
};

export function getResultByScore(scores, team1, team2, probability) {
  if (!scores || !probability) {
    return {
      val: '-',
      prob: '-',
    };
  }

  const probCalculator = (scoreGrid, predictions) => scoreGrid.map((sl) => sl.map((s) => predictions[s])
    .reduce((acc, b) => acc + b, 0)).reduce((acc, b) => acc + b, 0);

  let res = {
    val: 'Draw',
    prob: probCalculator(FootballS2, probability),
  };
  const isOtherScore = scores[0] === 'x' || scores[0] === 'X';
  if (isOtherScore ? scores[0] === 'X' && scores[1] === 'x' : scores[0] > scores[1]) {
    res = {
      val: `${team1.abbr}`,
      prob: probCalculator(FootballS1, probability),
    };
  }
  if (isOtherScore ? scores[0] === 'x' && scores[1] === 'X' : scores[0] < scores[1]) {
    res = {
      val: `${team2.abbr}`,
      prob: probCalculator(FootballS3, probability),
    };
  }

  return res;
}

export function scoresToStringWithInfo(scores, team1, team2, probability) {
  const value = scoresToString(scores);

  const scoreSplit = value?.split('or') || [];
  const infoArray = scoreSplit.map((it, i) => {
    const other = it.toLowerCase().includes('x:x');
    if (other) {
      const scoreGrid = it.includes('X:x') ? FootballS1 : it.includes('X:X') ? FootballS2 : FootballS3;
      const otherElem = it.includes('X:x') ? `${team1.name} win` : it.includes('X:X') ? 'Draw' : `${team2.name} win`;

      return (
        <OtherInfo
          key={it}
          scoreGrid={scoreGrid}
          predictions={probability}
          elem={otherElem}
          absolute
          rightPosition={Boolean(i)}
        />
      );
    }

    return '';
  });

  return (
    <>
      {infoArray}
      {value}
    </>
  );
}

export function getSideWinAndProbability(scores, team1, team2, probability) {
  if (!scores || !probability) {
    return '- / -';
  }
  const extracted = extractScoresAsId(scores);

  const sides = extracted.reduce((acc, n) => {
    const res = getResultByScore(scoresToString(n).split(':'), team1, team2, probability);
    if (!acc.find((it) => it.val === res.val)) {
      acc.push(res);
    }

    return acc;
  }, []);

  const values = sides.map((it) => it.val).join(' or ');
  const prob = extracted.map((score) => probability[score]).reduce((acc, n) => acc + n, 0);
  const sumProb = sides.map((it) => it.prob).reduce((acc, n) => acc + n, 0);

  return [values, round((100.0 - prob) / sumProb, 2)];
}

export function scoresToOutcomeString(scores) {
  let result = '';
  let end = '';

  extractScoresAsId(scores).forEach((s) => {
    if (s === FootballScores.OneZero || s === FootballScores.TwoZero || s === FootballScores.TwoOne
        || s === FootballScores.ThreeZero || s === FootballScores.ThreeOne || s === FootballScores.MaxZero) {
      result += '1';
    } else if (s === FootballScores.ZeroOne || s === FootballScores.ZeroTwo || s === FootballScores.OneTwo
        || s === FootballScores.ZeroThree || s === FootballScores.OneThree || s === FootballScores.ZeroMax) {
      result += '2';
    } else {
      end += 'X';
    }
  });

  return result + end;
}

export const competitionNames = {
  'spanish la liga': 'laliga',
  'mls': 'mlsusa',
  'english championship': 'championship',
  'french ligue': 'franceligue1',
  'europa league': 'europaleague',
  'conference league': 'conferenceleague',
  'italian seria': 'seriaa',
  'euro': 'euro',
  'bundesliga': 'bundesliga',
  'english premier league': 'englishpremierleague',
  'eurocups': 'eurocups',
  'uefa champions league': 'championsleague',
  'saudi pro league': 'roshnsaudi',
  'champions league': 'championsleague',
  'euroweek': 'euroweek'
}
