export const EventStateToStatus = {
  DRAFT: 'WAITING',
  SCHEDULED: 'WAITING',
  LIVE: 'ACTIVE',
  FINISHED: 'FINISHED',
  CANCELED: 'CANCELED',
};

export const PoolStateToStatus = {
  WAITING: 'WAITING',
  ACTIVE: 'ACTIVE',
  FINISHED: 'FINISHED',
  CANCELED: 'CANCELED',
};

export const Status = {
  WAITING: {
    description: 'Scheduled',
    name: 'scheduled',
    ind: 2,
  },
  ACTIVE: {
    description: 'Live',
    name: 'live',
    ind: 1,
  },
  FINISHED: {
    description: 'Finished',
    name: 'finished',
    ind: 0,
  },
  CANCELED: {
    description: 'Canceled',
    name: 'canceled',
    ind: 3,
  },
};
